.old-school-way {
  &__heading {
    font-family: $raleway-default;
    font-size: 1.5em;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: -0.05em;
    span {
      font-family: $raleway-default;
      font-weight: 700;
      font-size: 1.5em;
    }
  }
  &__showcase {
    &-container {
      margin: 1.5em 0;
      max-width: 568px;
      width: 100%;
    }

    &-list {
      list-style: none;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    &-list-item {
      flex: 0 0 44%;
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0.5em;
      transition: transform 150ms ease-in-out;
      cursor: pointer;
      @include bp('m-sm'){
        flex: 0 0 30%;
        padding: 0.5em;
      }
      &:hover {
        transform: scale(1.1);
      }
      a {
        color: #000;
        text-decoration: none; 
        &:hover, &:focus {
          text-decoration: underline;
        }
      }
    }

    &-image {
      display: block;
      padding: 0;
      margin: 0;
      width: 131px;
      max-width: 100%;
    }

    &-image-shine {
      display: block;
      position: relative;
      padding: 0;
      margin: 0 0 1em 0;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        -webkit-transition: none;
           -moz-transition: none;
                transition: none;
      }
      &:hover:after {
        width: 150%;
        background-color: rgba(255, 255, 255, 0);
        -webkit-transition: all 0.4s ease-in-out;
           -moz-transition: all 0.4s ease-in-out;
                transition: all 0.4s ease-in-out;
      }
    }

    &-rego-plate {
      width: 100%;
      display: block;
      text-align: center;
      margin: 0 0 0.5em 0;
    }

    &-price-description {
      width: 100%;
      display: block;
      text-align: center;
    }
    
    &-price-value {
      font-weight: 700;
    }
  }
  &__link {
    color: #000;
    &:hover,
    &:active,
    &:visited {
      color: #000;
    }
  }
}

.preview-container {
  display: block;
  background: url("../images/plates/bg-plate-share.jpg") no-repeat;
  background-size: contain;
  canvas {
    text-align: center;
  }
}