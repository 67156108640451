/**
Variables
A global collection of variables to use throughout the SCSS.
**/

/** Structural **/
$browser-context: 16px;

/** Colours **/

// Globally lighten and darken colours through SCSS.
// More often than not, the `-base` variant will be used.
$lighten-percent: 15%;
$darken-percent: 15%;

// Black
$black-base: #000000;
$black-light: lighten($black-base, $lighten-percent);
$black-dark: darken($black-base, $darken-percent);

// Yellow
$yellow-base: #FFC433;
$yellow-light: lighten($yellow-base, $lighten-percent);
$yellow-dark: darken($yellow-base, $darken-percent);

// Blue
$blue-base: #1877F2;
$blue-light: lighten($blue-base, $lighten-percent);
$blue-dark: darken($blue-base, $darken-percent);

// Grey
$grey-base: #F7F7F7;
$grey-light: lighten($grey-base, $lighten-percent);
$grey-dark: darken($grey-base, $darken-percent);

/** Animations **/

$anim-pulse-spacing: 10px;
$anim-pulse-multiplier: 1.25;
$anim-pulse-delay: 750ms;
$anim-pulse-ease: ease-in-out;

/** Mixins **/

// Cross-Browser Compatible Range Inputs
$track-color: #C7C7C7 !default;
$thumb-color: $yellow-base !default;

$thumb-radius: 12px !default;
$thumb-height: 24px !default;
$thumb-width: 24px !default;
$thumb-shadow-size: 4px !default;
$thumb-shadow-blur: 4px !default;
$thumb-shadow-color: rgba(0, 0, 0, .2) !default;
$thumb-border-width: 3px !default;
$thumb-border-color: #707070 !default;

$track-width: 100% !default;
$track-height: 8px !default;
$track-shadow-size: 1px !default;
$track-shadow-blur: 1px !default;
$track-shadow-color: #FFF !default;
$track-border-width: 2px !default;
$track-border-color: #FFF !default;

$track-radius: 5px !default;
$contrast: 5% !default;

$ie-bottom-track-color: darken($track-color, $contrast) !default;