.button {
  &__jump-to-content {
    display: none;
    @include bp("m-md") {
      display: block;
      $jumpToContentSize: 75px;
      width: $jumpToContentSize;
      height: $jumpToContentSize;
      background-color: $yellow-base;
      background-image: url('../images/arrow.png');
      background-repeat: no-repeat;
      background-position: center;
      border: em(3) solid #fff;
      border-radius: $jumpToContentSize / 2;
      margin: (-$jumpToContentSize / 2) auto ($jumpToContentSize / 4) auto;
      box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.25);
    }
  }
}
