/**
Animations
A global collection of animations to use throughout the SCSS.
**/

// Pulse

@keyframes pulseBackground {
  0% {
    background-position: left $anim-pulse-spacing top $anim-pulse-spacing,
      left $anim-pulse-spacing bottom $anim-pulse-spacing,
      right $anim-pulse-spacing top $anim-pulse-spacing,
      right $anim-pulse-spacing bottom $anim-pulse-spacing;
  }
  50% {
    background-position: left $anim-pulse-spacing * $anim-pulse-multiplier top
        $anim-pulse-spacing * $anim-pulse-multiplier,
      left $anim-pulse-spacing * $anim-pulse-multiplier bottom
        $anim-pulse-spacing * $anim-pulse-multiplier,
      right $anim-pulse-spacing * $anim-pulse-multiplier top $anim-pulse-spacing *
        $anim-pulse-multiplier,
      right $anim-pulse-spacing * $anim-pulse-multiplier bottom
        $anim-pulse-spacing * $anim-pulse-multiplier;
  }
  100% {
    background-position: left $anim-pulse-spacing top $anim-pulse-spacing,
      left $anim-pulse-spacing bottom $anim-pulse-spacing,
      right $anim-pulse-spacing top $anim-pulse-spacing,
      right $anim-pulse-spacing bottom $anim-pulse-spacing;
  }
}
