.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: em(40) em(20);
  transition: opacity 150ms ease-in-out;

  &__site-overlay {
    display: block;
    position: absolute;

    background-color: $yellow-base;

    z-index: 100;

    top: 0;
    left: 0;

    height: 0vh;
    width: 100%;

    transition: height 350ms ease-in-out;
  }

  &__results {
    padding: em(80) em(20);
  }
}
