.site-footer {
  display: flex;
  justify-content: center;
  background-color: $black-base;
  width: 100%;
  padding: em(20);

  @include bp("m-md") {
    padding: em(80) 0;
  }

  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include bp("m-lg") {
      width: 100%;
      justify-content: space-around;
      max-width: em(1200);
    }
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: center;

    // Department of Transport Logo
    &:nth-child(1) {
      order: 1;
      width: 50%;
      @include bp("m-lg") {
        order: 1;
        width: auto;
      }
    }

    // Links
    &:nth-child(2) {
      order: 4;
      width: 100%;
      ul {
        li {
          margin: em(10) 0;
          text-align: center;
          a {
            color: #fff;
          }
        }
      }
      @include bp("m-lg") {
        order: 2;
        width: auto;
        ul {
          li {
            display: inline-block;
            padding: em(10) em(20);
            border-right: 1px solid #FFF;
            &:last-of-type {
              border-right: none;
            }
          }
        }
      }
    }

    // PlatesWA.com Logo
    &:nth-child(3) {
      order: 2;
      width: 50%;
      @include bp("m-lg") {
        order: 3;
        width: auto;
      }
    }

    // Social Links
    &:nth-child(4) {
      order: 3;
      width: 100%;
      ul {
        display: block;
        margin: em(20) 0;
        li {
          display: inline-block;
          // margin: 0 em(15);
          a {
            padding: 0 em(15);
          }
        }
      }
      @include bp("m-lg") {
        order: 4;
        width: auto;
      }
    }

  }
}
