.a11y {
  &__container {
    &:not(:focus):not(:active) {
      position: absolute !important;
      height: 1px;
      width: 1px;
      overflow: hidden;
      clip: rect(1px, 1px, 1px, 1px);
      white-space: nowrap; /* added line */
    }
  }
  &__main-content {

  }
  &__footer-content {

  }
}

#test {
  font-family: $license-default;
}