// _form.scss

.personal-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: $raleway-default;

  width: 100%;
  @include bp('m-md'){
    max-width: em(568);
  }

  fieldset {
    width: 100%;
    display: block;
  }

  label {
    font-weight: 400;
    margin: em(10) 0;
    display: inline-block;
    font-size: em(18);
  }

  &--optional-label {
    text-align: end;
    padding-bottom: 10px;
    width: 50%;
    margin: 0 0 -40px auto;
  }

  input {
    width: 100%;
  }

  input[type="range"] {
    -webkit-appearance: none;
    background: transparent;
    margin: $thumb-height / 2 0;
    width: $track-width;

    &::-moz-focus-outer {
      border: 0;
    }

    &:focus {
      outline: 0;

      &::-webkit-slider-runnable-track {
        background: lighten($track-color, $contrast);
      }

      &::-ms-fill-lower {
        background: $track-color;
      }

      &::-ms-fill-upper {
        background: lighten($track-color, $contrast);
      }
    }

    &::-webkit-slider-runnable-track {
      @include track;
      @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
      background: $track-color;
      border: $track-border-width solid $track-border-color;
      border-radius: $track-radius;
    }

    &::-webkit-slider-thumb {
      @include thumb;
      -webkit-appearance: none;
      margin-top: ((-$track-border-width * 2 + $track-height) / 2 - $thumb-height / 2);
      transition: background-color 150ms ease-in-out;
      &:hover {
        // background-color: $yellow-dark;
      }
      &:focus {
        // outline: 3px solid red !important;
      }
    }

    &::-moz-range-track {
      @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
      @include track;
      background: $track-color;
      border: $track-border-width solid $track-border-color;
      border-radius: $track-radius;
      height: $track-height / 2;
    }

    &::-moz-range-thumb {
      @include thumb;
    }

    &::-ms-track {
      @include track;
      background: transparent;
      border-color: transparent;
      border-width: ($thumb-height / 2) 0;
      color: transparent;
    }

    &::-ms-fill-lower {
      @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
      background: $ie-bottom-track-color;
      border: $track-border-width solid $track-border-color;
      border-radius: ($track-radius * 2);
    }

    &::-ms-fill-upper {
      @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
      background: $track-color;
      border: $track-border-width solid $track-border-color;
      border-radius: ($track-radius * 2);
    }

    &::-ms-thumb {
      @include thumb;
      margin-top: $track-height / 4;
    }

    &:disabled {
      &::-webkit-slider-thumb,
      &::-moz-range-thumb,
      &::-ms-thumb,
      &::-webkit-slider-runnable-track,
      &::-ms-fill-lower,
      &::-ms-fill-upper {
        cursor: not-allowed;
      }
    }
  }

  input[type="text"]{
    border: em(1) solid $grey-dark;
    background-color: $grey-base;
    border-radius: em(2);
    height: em(50);
    padding: 0 em(10);
  }

  input[type="submit"] {
    width: 100%;
    max-width: em(300);
    height: em(70);
    background-color: #000;
    color: #FFF;
    background-image: url('../images/personal-form-submit-button-background--L-T.png'), url('../images/personal-form-submit-button-background--L-B.png'), url('../images/personal-form-submit-button-background--R-T.png'), url('../images/personal-form-submit-button-background--R-B.png');
    background-position: left 10px top 10px, left 10px bottom 10px, right 10px top 10px, right 10px bottom 10px;
    background-repeat: no-repeat;
    @supports not (-ms-high-contrast: none) { /* will render in all browsers except IE */
      animation: pulseBackground $anim-pulse-delay $anim-pulse-ease infinite; 
      animation-play-state: paused;
    }
    @supports (-ms-ime-align:auto) { /* disable effect in older versions of EDGE */
      animation: none;
    }
    margin: em(40) 0 em(20) 0;
    border: 0;
    cursor: pointer;
    &:focus {
      outline: 3px solid $yellow-base;
    }
    &:hover {
      animation-play-state: running;
    }
    &[disabled] {
      opacity: 0.5;
    }
  }

  &__label-container {
    display: flex;
    justify-content: space-between;
  }

  &__subheading {
    font-size: em(20);
    font-weight: 700;
    text-align: center;
    width: 100%;
    display: block;
    margin: em(20) 0 0 0;
  }

}