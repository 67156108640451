// _mixins.scss

// Hide from both screenreaders and browsers
@mixin hidden {
  display: none;
  visibility: hidden;
}

// Hide from browsers, not screenreaders.
@mixin visually-hidden() {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Undo `visually-hidden` when required.
@mixin visually-hidden-reset {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

// `bp`, include media-queries as breakpoints.
// https://css-tricks.com/snippets/sass/mixin-manage-breakpoints/

// These breakpoints were chosen after carefully comparing the following two sets of data:
// https://www.screensiz.es/
// http://devfacts.com/media-queries-breakpoints-2020/
// They almost make a perfect linear progression through the sizes of screens in 2020.
$breakpoints: (
  "m-xs": (
    min-width: em(320)
  ),
  "m-sm": (
    min-width: em(568)
  ),
  "m-md": (
    min-width: em(768)
  ),
  "m-lg": (
    min-width: em(1024)
  ),
  "m-xl": (
    min-width: em(1366)
  ),
  "d-sm": (
    min-width: em(1680)
  ),
  "d-md": (
    min-width: em(1920)
  ),
  "d-lg": (
    min-width: em(2400)
  )
);

@mixin bp($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

// Styling Cross-Browser Compatible Range Inputs with Sass
// https://github.com/darlanrod/input-range-sass

@mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
  box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color, 0 0 $shadow-size lighten($shadow-color, 5%);
}

@mixin track {
  cursor: default;
  height: $track-height;
  transition: all .2s ease;
  width: $track-width;
}

@mixin thumb {
  @include shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);
  background: $thumb-color;
  border: $thumb-border-width solid $thumb-border-color;
  border-radius: $thumb-radius;
  box-sizing: border-box;
  cursor: default;
  height: $thumb-height;
  width: $thumb-width;
  &:focus {
    outline: 3px solid red !important;
  }
}