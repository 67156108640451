/**
Main
A main entry-file for the SCSS to import other files in the IT(S)CSS method.
**/

// Settings
@import './settings/settings.scss';
// Tools
@import './tools/tools.scss';
// Generic
@import './generic/generic.scss';
// Elements
@import './elements/elements.scss';
// Objects
@import './objects/objects.scss';
// Components
@import './components/components.scss';
// Utilities
@import './utilities/utilities.scss';

// body {
//   border: 5px solid red;
// }