/**
Functions
A global collection of functions to utilise within SCSS.
**/

// `em`, convert px to em.
// https://css-tricks.com/snippets/sass/px-to-em-functions/

@function em($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }
  @if (unitless($context)) {
    $context: $context * 1px;
  }
  @return $pixels / $context * 1em;
}