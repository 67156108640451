html {
  // Reset the font-size for `em` and `rem` units.
  font-size: $browser-context;

  // Globally set the font to 'Raleway' as most of the design uses this.
  font-family: $open-sans-default;
}

body {
  position: relative;
}