.site-header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: $yellow-base;
  align-items: center;
  padding: em(25);
  @include bp("m-md") {
    padding: em(25) 0 em(50) 0;
  }
  &__heading {
    @include visually-hidden;
  }
  &__logo {
    width: 100%;
    height: auto;

    @include bp("m-md") {
      width: auto;
    }
    &--plateswa {
      margin: em(10) 0;
    }
    &--plate-predictor {
      margin: em(40) 0 0 0;
    }
  }
  &__logo-link {
    z-index: 10001;
  }
  &__sub-heading {
    font-size: em(22);
    text-align: center;
    font-weight: 700;
    max-width: em(600); 
    margin: em(10) 0;
  }
  &__copy {
    max-width: em(570);
    margin: em(10) 0;
    font-size: em(18);
    text-align: center;

    @include bp("m-md") {
      margin: em(20) 0;
    }
  }
  &__results-logo {
    display: none;
    $jumpToContentSize: 75px;
    margin: ($jumpToContentSize / 4) auto (-$jumpToContentSize * 1.7) auto;
    z-index: 100;
    @include bp('m-md'){
      margin: ($jumpToContentSize / 2) auto (-$jumpToContentSize * 2) auto;
    }
  }
}
