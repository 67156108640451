// 2.875em
.results {
  &__heading {
    font-family: $raleway-default;
    font-size: 1.5em;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: -0.05em;
    span {
      font-family: $raleway-default;
      font-weight: 700;
      font-size: 1.5em;
    }
  }
  &__description {
    max-width: 568px;
    width: 100%;
    margin: em(30) 0;
    font-family: $raleway-default;
    font-size: em(24);
    text-align: center;
    line-height: em(22);
    font-weight: 700;
  }
  &__result-plate {
    max-width: 300px;
    height: 70px;
    background: url("../images/plates/plate-base.png") center top no-repeat;
    background-size: contain;
    display: block;
    font-family: $license-default;
    font-size: em(35);
    text-align: center;
    width: 100%;
    padding: em(10) em(10) em(5) em(10);
    @include bp('m-sm'){
      height: 90px;
      padding: em(11) em(5) em(2.5) em(5);
      font-size: em(42);
    }
    @include bp('m-lg'){
      padding: em(11) em(10) em(5) em(10);
    }
    @include bp('m-xl'){
      padding: em(11) em(10) em(5) em(10);
    }
  }
  &__result-container {
    width: 100%;
    max-width: 1200px;
    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 em(30) 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include bp("m-lg") {
        flex-direction: row;
        flex-wrap: nowrap;
      }
      li {
        align-items: center;
        background-color: $yellow-base;
        border-radius: em(10);
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        margin: em(10) 0;
        padding: em(30) em(25);
        white-space: nowrap;
        width: 100%;
        max-width: 350px;

        @include bp('m-md') {
          margin: em(10);
        }

        @include bp('m-xl') {
          min-width: 33%;
        }

        &:first-of-type {
          margin-left: 0;
          background-color: #f7f7f7;
          opacity: 0.75;
          order: 2;
          @include bp("m-lg") {
            order: 1;
          }
        }
        &:last-of-type {
          margin-right: 0;
          background-color: #f7f7f7;
          opacity: 0.75;
          order: 3;
          @include bp("m-lg") {
            order: 3;
          }
        }
        &:nth-of-type(2) {
          z-index: 100;
          transition: transform 150ms ease-in-out, box-shadow 150ms ease-in-out;
          order: 1;
          @include bp("m-lg") {
            order: 2;
          }
          &:hover {
            transform: scale(1.05);
            box-shadow: 0px 0px em(30) rgba(0, 0, 0, 0.3);
          }
        }
      }
    }
    &-share {
      ul {
        display: block;
        li {
          display: block;
          position: relative;
          width: 100%;
          height: 314px;
          max-width: 600px;
          max-height: 314px;
          margin: 0;
          background: url("../images/plates/bg-plate-share.jpg") no-repeat;
          background-size: contain;
          .results__result-plate {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -80%);
            padding: 34px 26px 13px;
          }
        }
      }
    }
  }
  &__button {
    display: block;
    color: #000;
    font-weight: 700;
    &--facebook {
      background-color: $blue-base;
      border-radius: em(5);
      color: #fff;
      margin: em(10) 0 em(20) 0;
      padding: em(20) em(20) em(20) em(40);
      text-decoration: none;
      text-transform: uppercase;
      background-image: url("../images/social--facebook.png");
      background-position: left em(15) center;
      background-repeat: no-repeat;
      @include bp('m-sm'){
        margin: em(40) 0 em(20) 0;
      }
    }
    &--customise {
    }
  }
  &__function-buttons {
    display: block;
    padding: em(10);
    color: #000;
    &--edit {
    }
    &--explore {
    }
  }
}
